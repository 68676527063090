import { Box } from '@mui/material'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { Bandwidth, RequestBandwidth, ResponseBandwidth } from '../Bandwidth/Bandwidth'
import { ResolutionData, EntrySource, EntryDestination } from '../ResolutionData'
import { Entry } from '../../../EntryListItem/Entry'
import { ResourceType } from '../EntryData'
import { GenericDirectionIcon } from '../../../EntryListItem/icons/GenericDirectionIcon'

import styles from './FullResolutionData.module.sass'
import variables from '../../../../variables.module.scss'

const ResolutionContext = createContext({ getShownData: null, getGridItemColumnsNum: null })
export const useResolutionContext = () => useContext(ResolutionContext)

interface FullResolutionDataProps {
  entry: Entry
  showLongNames: boolean
  headingMode?: boolean
}

export const FullResolutionData: React.FC<FullResolutionDataProps> = ({
  entry,
  showLongNames,
  headingMode = false
}) => {
  const [node, setShowNode] = useState(false)
  const [namespace, setShowNamespace] = useState(false)
  const [service, setShowService] = useState(false)
  const [pod, setShowPod] = useState(true)

  useEffect(() => {
    const showAllResources = showLongNames || headingMode

    setShowNode(showAllResources)
    setShowNamespace(showAllResources)
    setShowService(showAllResources)
    setShowPod(true)
  }, [showLongNames, headingMode])

  return (
    <ResolutionContext.Provider
      value={{
        getShownData: (resourse: ResourceType) => {
          switch(resourse) {
          case 'name': return true
          case 'node': return node
          case 'namespace': return namespace
          case 'service': return service
          case 'pod': return pod
          }
        },
        getGridItemColumnsNum: (resourse: ResourceType) => {
          switch(resourse) {
          case 'name': return 11
          case 'node': return showLongNames || headingMode ? 3 : 0
          case 'namespace': return showLongNames || headingMode ? 2 : 0
          case 'service': return showLongNames || headingMode ? 2 : 0
          case 'pod': return showLongNames || headingMode ? 5 : 11
          }
        }
      }}
    >
      <Box className={styles.FullResolutionData}>
        <Box className={styles.ResolutionDataContainer}>
          <ResolutionData
            entry={entry}
            point={EntrySource}
            showLongNames={showLongNames}
          />
          {!headingMode && entry.proto.name !== 'tcp' && (
            <Bandwidth type={RequestBandwidth} size={entry.requestSize} />
          )}
        </Box>
        <Box display='flex' ml={ headingMode ? '5px' : null}>
          <GenericDirectionIcon fill={entry.error ? variables.failureColor : entry.proto.backgroundColor} />
        </Box>
        <Box className={styles.ResolutionDataContainer}>
          <ResolutionData
            entry={entry}
            point={EntryDestination}
            showLongNames={showLongNames}
          />
          {!headingMode && entry.proto.name !== 'tcp' && (
            <Bandwidth type={ResponseBandwidth} size={entry.responseSize} />
          )}
        </Box>
      </Box>
    </ResolutionContext.Provider>
  )
}
