import React from 'react'
import { Box } from '@mui/material'
import { EntryData } from './EntryData'
import { Utils } from '../../../helpers/Utils'
import { EntryPoint } from '../../../types/trafficEntry'

interface NamespaceBadgeProps {
  namespace: string
  point: EntryPoint
  color: string,
  resolutionMechanism: string
}

export const NamespaceBadge: React.FC<NamespaceBadgeProps> = ({
  namespace,
  point,
  color,
  resolutionMechanism
}) => {
  return (
    <Box
      className='NamespaceContainer'
      border={`
        ${namespace ? 0.9 : 1.3}px
        ${namespace ? 'solid' : 'dashed'} ${color}
      `}
    >
      <Box
        position='absolute'
        top={0}
        left={0}
        bottom={0}
        right={0}
        height='100%'
        width='100%'
        bgcolor={color}
        sx={{
          opacity: 0.2,
          zIndex: 1
        }}
      />
      <EntryData
        resource='namespace'
        name={namespace ? Utils.truncateString(namespace, 20) : ''}
        query={`${point}.namespace == "${namespace}"`}
        dataClass='DataBadge'
        unresolvedText={resolutionMechanism === 'dns' ? 'external' : 'unresolved'}
        zIndex={2}
      />
    </Box>
  )
}
