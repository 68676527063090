import React from 'react'
import { OidcAuth } from './OidcAuth/OidcAuth'
import { SamlAuth } from './SamlAuth/SamlAuth'
import { OidcAuthType, SamlAuthType } from '../../../consts'
import { appAuthEnabled, appAuthType } from '../../../types/global'
import { useRecoilValue } from 'recoil'
import shouldAuthenticateAtom from '../../../recoil/shouldAuthenticate/atom'

interface AuthProps {
  children?: React.ReactNode | React.ReactNode[]
}

export const Auth: React.FC<AuthProps> = ({ children }) => {
  if (!appAuthEnabled()) {
    return <></>
  }

  const authType = appAuthType()

  switch (authType) {
  case OidcAuthType:
    return <OidcAuth>{children}</OidcAuth>
  case SamlAuthType:
    return <SamlAuth>{children}</SamlAuth>
  default:
    console.error('Unsupported auth type:', authType)
    return <></>
  }
}

interface AuthInterface {
  shouldAuthenticate: boolean
}

export const useAuth = (): AuthInterface => {
  const shouldAuthenticate = useRecoilValue(shouldAuthenticateAtom)

  return { shouldAuthenticate }
}
