import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { Entry } from '../EntryListItem/Entry'

import './TrafficEntry.module.sass'

import entryDetailsOpenAtom from '../../recoil/entryDetailsOpen/atom'
import focusedItemAtom from '../../recoil/focusedItem/atom'
import focusedRecordAtom from '../../recoil/focusedRecord/atom'
import focusedStreamAtom from '../../recoil/focusedStream/atom'
import entryDetailsViewModeAtom from '../../recoil/entryDetailsViewMode'
import timezoneAtom from '../../recoil/timezone/atom'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'


import Protocol from '../UI/Protocol/Protocol'
import StatusCode from '../UI/StatusCode/StatusCode'
import { TlsIndicator } from './components/TlsIndicator'
import { resolveTrafficEntryStyles } from './helpers'
import { Method } from './components/Method'
import { Route } from './components/Route'
import { FullResolutionData } from './components/FullResolutionData/FullResolutionData'
import { IpWithPort } from './components/IpWithPort/IpWithPort'
import { GenericDirectionIcon } from '../EntryListItem/icons/GenericDirectionIcon'
import { Timestamp } from './components/Timestamp'
import useWindowDimensions from '../../hooks/WindowDimensionsHook'
import { DuplicateBadge } from './components/DuplicateBadge/DuplicateBadge'
import focusedEntryAtom from '../../recoil/focusedEntry'
import classNames from 'classnames'
import { CaptureBackend } from './components/CaptureBackend'
import variables from '../../variables.module.scss'
import { Bandwidth, TotalBandwidth } from './components/Bandwidth/Bandwidth'
import useStreamingList from '../TrafficViewer/useStreamingList'
import { RecordedBadge } from './components/RecordedBadge'
import { NamespaceBadge } from './components/NamespaceBadge'
import { EntryDestination, EntrySource } from '../../types/trafficEntry'
import { ErrorBadge } from './components/ErrorBadge'

interface TrafficEntryProps {
  entry: Entry
  duplicateOrigin?: Entry
  headingMode?: boolean
  style?: unknown
}

export const TrafficEntry: React.FC<TrafficEntryProps> = ({
  entry,
  duplicateOrigin = null,
  headingMode = false,
  style
}) => {
  const setFocusedEntry = useSetRecoilState(focusedEntryAtom)
  const [focusedItem, setFocusedItem] = useRecoilState(focusedItemAtom)
  const [focusedStream, setFocusedStream] = useRecoilState(focusedStreamAtom)
  const [entryDetailsOpen, setEntryDetailsOpen] = useRecoilState(entryDetailsOpenAtom)
  const setFocusedRecord = useSetRecoilState(focusedRecordAtom)

  const entryDetailsViewMode = useRecoilValue(entryDetailsViewModeAtom)

  const [showLongNames, setShowLongNames] = useState(false)

  const { setToBottom } = useStreamingList()

  useEffect(() => {
    setShowLongNames(!entryDetailsOpen || entryDetailsOpen && entryDetailsViewMode === 'drawer')
  }, [entryDetailsOpen, entryDetailsViewMode])

  const handleFocusOnEntry = () => {
    setToBottom(false)
    setFocusedEntry(entry)
    setFocusedItem(entry.id)
    setFocusedStream(entry.stream)
    setFocusedRecord(entry.record)
    setEntryDetailsOpen(true)
  }

  const isSelected = focusedItem === entry.id

  const timezone = useRecoilValue(timezoneAtom)

  const { width: windowWidth } = useWindowDimensions();

  const trafficEntryClasses = classNames(
    'TrafficEntry',
    {
      'selected': isSelected,
      'error': entry.error,
    }
  );

  return (
    <Box
      id={entry.key}
      className={trafficEntryClasses}
      style={resolveTrafficEntryStyles(entry, focusedItem, focusedStream, headingMode, style)}
      boxShadow={!headingMode ? variables.lightShadowBottom : null}
      onClick={handleFocusOnEntry}
    >
      {!headingMode && <Protocol
        protocol={entry.proto}
        horizontal={false}
        error={entry.error !== null}
      />}
      <TlsIndicator tls={entry.tls} />
      <Box className='TrafficEntryResolution'>
        <Box className='TrafficEntrySummary'>
          <ErrorBadge error={entry.error} />
          <Method name={entry.method} query={entry.methodQuery} />
          <StatusCode
            statusCode={entry?.status}
            statusQuery={entry.statusQuery}
            protocol={entry.proto.name}
          />
          {entry.proto.name === 'tcp' && <Bandwidth type={TotalBandwidth} size={entry.size} />}
          <Route name={entry.summary} truncateName={!showLongNames && !headingMode} query={entry.summaryQuery} />
        </Box>
        <FullResolutionData entry={entry} showLongNames={showLongNames} headingMode={headingMode} />
      </Box>
      <Box
        display='flex'
        alignItems='center'
        gap='5px'
        mr='5px'
      >
        {(headingMode || (!entryDetailsOpen || windowWidth >= 2300)) && (
          <>
            <NamespaceBadge
              namespace={entry.src.namespace}
              point={EntrySource}
              color={entry.src?.namespaceColorSet?.color}
              resolutionMechanism={entry.src.resolutionMechanism}
            />
            <GenericDirectionIcon fill={entry.error ? variables.failureColor : entry.proto.backgroundColor} />
          </>
        )}
        {(headingMode || (!entryDetailsOpen || windowWidth >= 1200)) && <NamespaceBadge
          namespace={entry.dst.namespace}
          point={EntryDestination}
          color={entry.dst?.namespaceColorSet?.color}
          resolutionMechanism={entry.dst.resolutionMechanism}
        />}
      </Box>
      <DuplicateBadge duplicate={entry.duplicate !== ''} duplicateOrigin={duplicateOrigin} />
      <CaptureBackend capture={entry.capture} />
      <RecordedBadge recordName={entry.record} />
      <Box className='TrafficEntryIps'>
        <IpWithPort ip={entry.src.ip} port={entry.src.port} point='src' />
        <GenericDirectionIcon fill={entry.error ? variables.failureColor : entry.proto.backgroundColor} />
        <IpWithPort ip={entry.dst.ip} port={entry.dst.port} point='dst' />
      </Box>
      {(!entryDetailsOpen || windowWidth > 1760) && (
        <>
          <Box className='TimestampDivider'></Box>
          <Timestamp unixTimestamp={entry.timestamp} timezone={timezone} />
        </>
      )}
    </Box>
  )
}
