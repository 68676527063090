import React from 'react'
import variables from '../../../../variables.module.scss'
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded'
import { CircularProgress, Fab } from '@mui/material'
import { authorizeAction, AUTHZ_ACTIONS } from '../../../UI/Auth/SamlAuth/Authorization'
import Tooltip from '@mui/material/Tooltip'

interface ActivateScriptButtonProps {
  darkModeEnabled: boolean
  inProgress: boolean
  onClick: () => void
}

export const ActivateScriptButton: React.FC<ActivateScriptButtonProps> = ({ darkModeEnabled, inProgress, onClick }) => {
  return (
    <Tooltip
      title={
        !authorizeAction({
          [AUTHZ_ACTIONS.SCRIPTING_PERMISSIONS_KEY]: AUTHZ_ACTIONS.SCRIPTING_PERMISSIONS.CAN_ACTIVATE
        }) ?
          'You are not authorized to activate scripts' : null
      }
      placement='top-start'
      arrow
    >
      <Fab
        variant='extended'
        color='success'
        onClick={onClick}
        disableRipple={
          inProgress ||
          !authorizeAction({
            [AUTHZ_ACTIONS.SCRIPTING_PERMISSIONS_KEY]: AUTHZ_ACTIONS.SCRIPTING_PERMISSIONS.CAN_ACTIVATE
          })
        }
        sx={{
          backgroundColor: () => {
            if (!authorizeAction({
              [AUTHZ_ACTIONS.SCRIPTING_PERMISSIONS_KEY]: AUTHZ_ACTIONS.SCRIPTING_PERMISSIONS.CAN_ACTIVATE
            })) {
              return variables.darkSlateColor
            }

            return darkModeEnabled
              ? variables.lightSuccessColor
              : variables.successColor
          },
          opacity: inProgress ||
            !authorizeAction({
              [AUTHZ_ACTIONS.SCRIPTING_PERMISSIONS_KEY]: AUTHZ_ACTIONS.SCRIPTING_PERMISSIONS.CAN_ACTIVATE
            }) ? 0.8 : 1,
          filter: `grayscale(${!authorizeAction({
            [AUTHZ_ACTIONS.SCRIPTING_PERMISSIONS_KEY]: AUTHZ_ACTIONS.SCRIPTING_PERMISSIONS.CAN_ACTIVATE
          }) ? 1 : 0})`,
          cursor: inProgress ||
            !authorizeAction({
              [AUTHZ_ACTIONS.SCRIPTING_PERMISSIONS_KEY]: AUTHZ_ACTIONS.SCRIPTING_PERMISSIONS.CAN_ACTIVATE
            }) ? 'not-allowed' : 'pointer',
        }}
      >
        {inProgress && <CircularProgress size={14} color='inherit' sx={{ mr: '10px' }} />}
        {!inProgress && (
          <PlayArrowRoundedIcon
            htmlColor={
              darkModeEnabled
                ? variables.successColor
                : variables.mainBackgroundColor
            }
            sx={{ mr: '10px' }}
          />
        )}
        {inProgress ? 'Activating now...' : 'Activate'}
      </Fab>
    </Tooltip>
  )
}
