import { useCallback, useEffect, useState } from 'react'
import {
  LicenseApiCapacityOption,
  LicenseExpiryOption,
  LicenseInfo
} from '../types/license'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import nodeLimitOverflowAtom from '../recoil/nodeLimitOverflow/atom'
import {
  HubBaseUrl,
  LicenseAnonymousEdition,
  LicenseCommunityEdition,
  LicenseEnterpriseEdition,
  LicenseOndemandEdition,
  LicenseProCompEdition,
  LicenseProEdition,
  LicenseProUnlEdition
} from '../consts'
import { toast } from 'react-toastify'
import { Utils } from '../helpers/Utils'
import licenseInfoAtom from '../recoil/licenseInfo/atom'
import onlineAtom from '../recoil/online'

import { diff } from 'deep-object-diff';
import trafficPlaybackAtom, { TRAFFIC_PLAYBACK_OK, TRAFFIC_PLAYBACK_STOPPED } from '../recoil/trafficPlayback/atom'
import licenseValidAtom from '../recoil/licenseValid'

interface LicenseInfoState {
  ready: boolean
  licenseInfo: LicenseInfo
  nodeLimitOverflow: boolean
  setNodeLimitOverflow: (isOverflow: boolean) => void
  expired: boolean
  valid: boolean
  commercialEdition: boolean
  internetRequiredEdition: boolean
  apiCapacityOption: LicenseApiCapacityOption
  expiryOption: LicenseExpiryOption
  getLicense: (setValidity?: boolean) => Promise<LicenseInfo | void>
  postPushLicense: (license: string) => Promise<void | Response>
  online: boolean,
}

const useLicense = (): LicenseInfoState => {
  const [ready, setReady] = useState(true)
  const [licenseInfo, setLicenseInfo] = useRecoilState(licenseInfoAtom)
  const [nodeLimitOverflow, setNodeLimitOverflow] = useRecoilState(
    nodeLimitOverflowAtom
  )
  const [expired, setExpired] = useState(false)
  const [valid, setValid] = useRecoilState(licenseValidAtom)
  const [commercialEdition, setCommercialEdition] = useState(false)
  const [internetRequiredEdition, setInternetRequiredEdition] = useState(false)

  const setTrafficPlayback = useSetRecoilState(trafficPlaybackAtom)

  const online = useRecoilValue(onlineAtom)

  const [apiCapacityOption, setApiCapacityOption] =
    useState<LicenseApiCapacityOption>(LicenseApiCapacityOption.Limited)
  const [expiryOption, setExpiryOption] = useState<LicenseExpiryOption>(
    LicenseExpiryOption.Ends
  )

  // API capacity & expiry behavior for each license edition
  useEffect(() => {
    if (licenseInfo === null) {
      setReady(false)
      return
    } else {
      setReady(true)
    }

    switch (licenseInfo.doc.edition) {
    case LicenseAnonymousEdition:
      setApiCapacityOption(LicenseApiCapacityOption.Unlimited)
      setExpiryOption(LicenseExpiryOption.Endless)
      break
    case LicenseCommunityEdition:
      setApiCapacityOption(LicenseApiCapacityOption.Unlimited)
      setExpiryOption(LicenseExpiryOption.Endless)
      break
    case LicenseProEdition:
      setApiCapacityOption(LicenseApiCapacityOption.Limited)
      setExpiryOption(LicenseExpiryOption.Endless)
      break
    case LicenseEnterpriseEdition:
      setApiCapacityOption(LicenseApiCapacityOption.Unset)
      setExpiryOption(LicenseExpiryOption.Ends)
      break
    case LicenseOndemandEdition:
      setApiCapacityOption(LicenseApiCapacityOption.Unlimited)
      setExpiryOption(LicenseExpiryOption.Endless)
      break
    case LicenseProCompEdition:
      setApiCapacityOption(LicenseApiCapacityOption.Limited)
      setExpiryOption(LicenseExpiryOption.Ends)
      break
    case LicenseProUnlEdition:
      setApiCapacityOption(LicenseApiCapacityOption.Unlimited)
      setExpiryOption(LicenseExpiryOption.Ends)
      break
    }

    setNodeLimitOverflow(
      licenseInfo?.currentNodeCount > licenseInfo?.maxNodeCount
    )
    setExpired(
      licenseInfo.doc.end > 0 && Date.now() > licenseInfo.doc.end
    )
    const internetRequiredEdition = licenseInfo.doc.edition !== '' &&
      licenseInfo.doc.edition !== LicenseCommunityEdition &&
      licenseInfo.doc.edition !== LicenseAnonymousEdition &&
      licenseInfo.doc.edition !== LicenseEnterpriseEdition

    setCommercialEdition(
      licenseInfo.doc.edition !== '' &&
      licenseInfo.doc.edition !== LicenseAnonymousEdition
    )
    setInternetRequiredEdition(internetRequiredEdition)
  }, [licenseInfo, online])

  const postPushLicense = useCallback((license: string) => {
    return fetch(
      `${HubBaseUrl}/license/push`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Kubeshark-Capture': 'ignore',
        },
        body: JSON.stringify({ license })
      },
    )
      .then(response => response)
      .catch(err => {
        if (err.length > 0) {
          console.error(err)
          toast.error(err.toString(), {
            theme: "colored"
          })
        }
      })
  }, [])

  const getLicense = (setValidity = true) => {
    let status: number

    return Utils.fetchWithTimeout(
      `${HubBaseUrl}/license`,
      {
        method: 'GET',
        headers: {
          'X-Kubeshark-Capture': 'ignore',
        },
        timeout: 8000,
      })
      .then((response) => {
        status = response.status
        return response.ok
          ? response
          : response.text().then((err) => Promise.reject(err))
      })
      .then((response) => response.json())
      .then((licenseInfo: {license: LicenseInfo}) => {
        setLicenseInfo(prevLicenseRecoil => {
          const licenseDiff = diff(licenseInfo.license, prevLicenseRecoil)
          if (prevLicenseRecoil === null || Object.keys(licenseDiff).length > 0) {
            return licenseInfo.license
          }

          return prevLicenseRecoil
        })

        if (setValidity) {
          setValid(
            licenseInfo.license.valid &&
            licenseInfo.license.maxNodeCount > 0
          )
        }

        setTrafficPlayback(licenseInfo.license.valid ? TRAFFIC_PLAYBACK_OK : TRAFFIC_PLAYBACK_STOPPED)

        return licenseInfo.license
      })
      .catch((err) => {
        if (err.length > 0) {
          console.error(err)
          if (status > 400 && status !== 504 && status !== 502) {
            toast.error(err.toString(), {
              theme: 'colored'
            })
          }
        }
      })
  }

  return {
    ready,
    licenseInfo,
    nodeLimitOverflow,
    setNodeLimitOverflow,
    expired,
    valid,
    commercialEdition,
    internetRequiredEdition,
    apiCapacityOption,
    expiryOption,
    getLicense,
    postPushLicense,
    online,
  }
}

export default useLicense
