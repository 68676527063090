import { ColorGreen, ColorRed, ColorWhite } from "../../consts"
import { Entry } from "../EntryListItem/Entry"

import variables from '../../variables.module.scss'

export const resolveTrafficEntryStyles = (entry: Entry, focusedItem: unknown, focusedStream: unknown, headingMode: boolean, style: unknown) => {
  const isSelected = focusedItem === entry.id
  const isTcpSelected = focusedStream === entry.stream

  const borderSize = entry.error ? '1.5px' : '1px'

  const borderStyle =
    !headingMode && !isSelected && isTcpSelected ? 'dashed' : 'solid'
  let transparentBorder =
    !headingMode && isTcpSelected ? entry.proto.backgroundColor : 'transparent'

  if (entry.error) {
    transparentBorder = variables.failureColor
  }

  let backgroundColor = entry.passed
    ? ColorGreen
    : entry.failed
      ? ColorRed
      : ColorWhite

  if (entry.error) {
    backgroundColor = `repeating-linear-gradient(45deg, ${variables.lightestFailureColor}, ${variables.lightestFailureColor} 10px, ${variables.mainBackgroundColor} 10px, ${variables.mainBackgroundColor} 31px)`
  }

  const standardStyles: React.CSSProperties = {
    border: isSelected
      ? `${borderSize} ${entry.proto.backgroundColor} ${borderStyle}`
      : `${borderSize} ${transparentBorder} ${borderStyle}`,
    position: 'absolute',
    top: style['top'],
    marginTop: style['marginTop'],
    width: '100%',
    background: backgroundColor,
    minHeight: '52px',
    maxHeight: '52px'
  }

  const headingModeStyles: React.CSSProperties = {
    border: `${borderSize} ${transparentBorder} ${borderStyle}`,
    position: 'unset',
    top: style['top'],
    marginTop: '10px',
    width: '100%',
    background: backgroundColor
  }

  return headingMode ? headingModeStyles : standardStyles
}

export const resolveQuery = (queryLeftOperand: string, operator: '==' | '>=' | '<=', value: unknown, emptyOnValue = '-') => {
  if (value === emptyOnValue) {
    value = ''
  }

  return `${queryLeftOperand} ${operator} "${value}"`
}
