import React from 'react'
import '../../TrafficEntry.module.sass'
import styles from './UnresolvedFieldBadge.module.sass'
import { Box } from '@mui/material'

interface UnresolvedFieldBadgeProps {
  text?: string
  simplified?: boolean
}

export const UnresolvedFieldBadge: React.FC<UnresolvedFieldBadgeProps> = ({ text = 'unresolved', simplified = false }) => {
  return (
    <Box className={`${simplified ? styles.SimplifiedBadge : styles.UnresolvedFieldBadge}`}>
      <span className='DataText'>
        {text}
      </span>
    </Box>
  )
}
