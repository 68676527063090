import React from 'react'
import { Box, Button } from '@mui/material'

import {
  CloudOffRounded,
  LoginRounded as LoginRoundedIcon,
  LogoutRounded as LogoutRoundedIcon
} from '@mui/icons-material'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import showAuthDialogAtom from '../../../recoil/showAuthDialog/atom'
import authSessionLogoutAtom from '../../../recoil/authSessionLogout/atom'
import authSessionOkAtom from '../../../recoil/authSessionOk/atom'
import onlineAtom from '../../../recoil/online'

interface AuthButtonProps {
  size: 'small' | 'large' | 'medium'
  fullWidth?: boolean
}

export const AuthButton: React.FC<AuthButtonProps> = ({ size, fullWidth }) => {
  const authSessionOk = useRecoilValue(authSessionOkAtom)
  const online = useRecoilValue(onlineAtom)
  const setAuthSessionLogout = useSetRecoilState(authSessionLogoutAtom)
  const setShowAuthDialog = useSetRecoilState(showAuthDialogAtom)

  const handleLogin = () => {
    setShowAuthDialog(true)
  }

  const handleLogout = () => {
    setAuthSessionLogout(Date.now())
  }

  return (
    <Box display='flex' flexDirection='column' alignItems='center' gap='10px'>
      <Button
        variant='contained'
        className={`themeButton ${online ? authSessionOk ? 'secondary' : 'primary' : 'gray'}`}
        fullWidth={fullWidth}
        size={size}
        startIcon={
          online ? authSessionOk ?
            <LogoutRoundedIcon sx={{ marginRight: '5px' }} /> :
            <LoginRoundedIcon sx={{ marginRight: '5px' }} /> :
            <CloudOffRounded sx={{ marginRight: '5px' }} />
        }
        onClick={online ? authSessionOk ? handleLogout : handleLogin : null}
        sx={{
          pointerEvents: online ? 'all' : 'none'
        }}
      >
        {online ? authSessionOk ? 'Logout' : 'Login' : 'No Internet'}
      </Button>
    </Box>
  )
}
