import React from 'react'
import { Box } from '@mui/material'

import variables from '../../../variables.module.scss'
import Queryable from '../../UI/Queryable/Queryable'
import { KsError } from '../../EntryListItem/Entry'

interface ErrorBadgeProps {
  error?: KsError
}

export const ErrorBadge: React.FC<ErrorBadgeProps> = ({ error }) => {
  if (!error) {
    return null
  }

  return (
    <Box
      boxSizing='border-box'
      display='flex'
      alignItems='center'
      height='18px'
      padding='1px 4px'
      borderRadius='4px'
      bgcolor={variables.failureColor}
    >
      <Queryable
        query={`error.type == "${error.type}"`}
        displayIconOnMouseOver={true}
        flipped={true}
        iconStyle={{
          transform: 'translate(-18px, 0)',
        }}
        tooltipStyle={{
          transform: 'translate(0, -20px)',
          fontFamily: variables.textFontFamily,
        }}
      >
        <span
          style={{
            fontFamily: 'Roboto, sans-serif',
            fontSize: '10px',
            fontWeight: 600,
            textTransform: 'uppercase',
            letterSpacing: '0.02em',
            color: variables.mainBackgroundColor
          }}
        >
          Error
        </span>
      </Queryable>
    </Box>
  )
}
