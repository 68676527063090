import React, { useCallback, useEffect, useState } from 'react'
import { LicenseExpiryOption } from '../../../types/license'

import { Modal, Box, Typography } from '@mui/material'
import { useRecoilValue } from 'recoil'
import { LicenseProEdition } from '../../../consts'
import { Utils } from '../../../helpers/Utils'
import timezoneAtom from '../../../recoil/timezone/atom'
import { appCloudLicenseEnabled } from '../../../types/global'
import { LicenseMetric } from './LicenseMetric/LicenseMetric'
import { LicenseIssueCard } from '../../UI/LicenseIssueCard/LicenseIssueCard'
import { UpgradeLicenseButton } from '../../UI/UpgradeLicenseButton/UpgradeLicenseButton'

import styles from './LicenseInfoModal.module.sass'
import variables from '../../../variables.module.scss'

import KeyRoundedIcon from '@mui/icons-material/KeyRounded'
import AppsRoundedIcon from '@mui/icons-material/AppsRounded'
import InsertInvitationRoundedIcon from '@mui/icons-material/InsertInvitationRounded'
import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded'
import { LicenseBadge } from './LicenseBadge/LicenseBadge'
import { AuthButtonWide } from '../../UI/AuthButton/AuthButtonWide'

import useLicense from '../../../hooks/useLicense'
import { LicenseIssueText } from './LicenseIssueText/LicenseIssueText'
import { LicenseeInfo } from './LicenseeInfo/LicenseeInfo'
import authSessionOkAtom from '../../../recoil/authSessionOk/atom'
// import { ContactUsButton } from '../../UI/ContactUsButton/ContactUsButton'

export const LicenseInfoModal: React.FC = () => {
  const [open, setOpen] = useState(false)

  const {
    ready: licenseReady,
    licenseInfo: info,
    nodeLimitOverflow,
    expired,
    valid,
    commercialEdition,
    expiryOption,
    online
  } = useLicense()

  const handleCloseModal = useCallback(() => {
    if (!valid) {
      return
    }

    setOpen(false)
  }, [valid])

  const [nodeLimitIconColor, setNodeLimitIconColor] = useState(variables.grayColor)
  const [nodeLimitValueColor, setNodeLimitValueColor] = useState('')

  useEffect(() => {
    setNodeLimitIconColor(
      nodeLimitOverflow ? variables.upgradeColor : variables.grayColor
    )
    setNodeLimitValueColor(
      nodeLimitOverflow ? variables.upgradeColor : variables.successColor
    )
  }, [nodeLimitOverflow])

  const [expiryIconColor, setExpiryIconColor] = useState(variables.grayColor)
  const [displayedExpiry, setDisplayedExpiry] = useState<string | JSX.Element>('Never')

  const timezone = useRecoilValue(timezoneAtom)

  useEffect(() => {
    if (!licenseReady || info === null) {
      return
    }

    setExpiryIconColor(expired ? variables.upgradeColor : variables.grayColor)
    if (info.doc.end < 0) {
      setDisplayedExpiry(
        <span style={{ color: expired ? variables.upgradeColor : variables.fontColor, fontWeight: 600 }}>
          Pending Activation
        </span>
      )
    } else {
      setDisplayedExpiry(
        <span style={{ color: expired ? variables.upgradeColor : variables.fontColor, fontWeight: 600 }}>
          {Utils.TimezoneMoment(timezone, +info.doc.end).format('MM/DD/YYYY, h:mm A (UTCZ)')}
        </span>
      )
    }
  }, [licenseReady, expired, info])

  const [displayedExpiryTitle, setDisplayedExpiryTitle] =
    useState<string>('Expires')

  useEffect(() => {
    if (!licenseReady) {
      return
    }

    if (expiryOption === LicenseExpiryOption.Endless) {
      if (info.doc.edition === LicenseProEdition) {
        setDisplayedExpiryTitle('Renews')
      } else {
        setDisplayedExpiry('Never')
      }
    }
  }, [licenseReady, expiryOption, info])

  const authSessionOk = useRecoilValue(authSessionOkAtom)

  useEffect(() => {
    if (!licenseReady) {
      return
    }

    setOpen(!valid)

    // if (valid && info.maxNodeCount > 0) {
    //   setOpen(nodeLimitOverflow)
    // }
  }, [info, licenseReady, valid])

  return (<>
    <LicenseBadge
      dropdownOpen={open}
      setDropdownOpen={setOpen}
    />
    {open && <Modal open onClose={handleCloseModal} disableAutoFocus>
      <Box className={styles.LicenseInfoModal}>
        <Box className={styles.TitleContainer}>
          <Box display='flex' alignItems='center' gap='10px'>
            <KeyRoundedIcon htmlColor={variables.blueColor} />
            <Typography variant='h6' className={styles.TitleText}>
              {!appCloudLicenseEnabled() && info.doc.edition}
              {appCloudLicenseEnabled() && commercialEdition && info.doc.edition}
              {appCloudLicenseEnabled() && !commercialEdition && online && 'LOG IN TO CONTINUE'}
              {appCloudLicenseEnabled() && !commercialEdition && !online && 'DOWNLOAD YOUR LICENSE KEY'}
            </Typography>
          </Box>
          {appCloudLicenseEnabled() && online && nodeLimitOverflow && (
            <UpgradeLicenseButton
              size='small'
              icon={!(appCloudLicenseEnabled() && !commercialEdition)}
            />
          )}
          {/* <HelpIconButton
            tooltipTitle='Learn more about other editions'
            link='https://www.kubeshark.co/pricing'
          /> */}
        </Box>
        {((!valid && commercialEdition) || nodeLimitOverflow) && <LicenseIssueCard theme='error' title='License issue' text={<LicenseIssueText />} />}
        {(!commercialEdition && !nodeLimitOverflow) && <LicenseIssueCard theme='info' title='Information' text={<LicenseIssueText />} />}
        {online && info.doc.edition === "" ? (
          <AuthButtonWide
            size='medium'
            loginTooltip='Log in / sign up to continue'
          />
        ) : (
          <>
            <LicenseMetric
              title='Node limit'
              value={
                <span
                  style={{
                    fontWeight: 700,
                    color: nodeLimitValueColor
                  }}
                >
                  {info.currentNodeCount}/{info.maxNodeCount}
                </span>
              }
              icon={<AppsRoundedIcon htmlColor={nodeLimitIconColor} />}
              error={nodeLimitOverflow}
            />
            <LicenseMetric
              title={displayedExpiryTitle}
              value={displayedExpiry}
              icon={
                <InsertInvitationRoundedIcon htmlColor={expiryIconColor} />
              }
              error={expired}
            />
            {appCloudLicenseEnabled() && commercialEdition && <LicenseMetric
              title='Licensee information'
              icon={
                <ManageAccountsRoundedIcon htmlColor={variables.grayColor} />
              }
              horizontalValueElement={<LicenseeInfo closeLicenseInfoModal={() => setOpen(false)} />}
            />}
            {appCloudLicenseEnabled() && online && !commercialEdition && !authSessionOk && (
              <AuthButtonWide
                size='medium'
                loginTooltip='Sign up to activate your license'
              />
            )}
          </>
        )}
        {/*!online && <ContactUsButton size='medium' />*/}
        {/*!appCloudLicenseEnabled() && online && (
          <UpgradeLicenseButton
            size='medium'
            fullWidth
            icon={true}
          />
        )*/}
      </Box>
    </Modal>}
  </>)
}
