import React, { useRef } from 'react'
import { VirtualizedListHandle } from '../../UI/VirtualizedList/VirtualizedList'

import { useRecoilState, useRecoilValue } from 'recoil'

import { EntriesList } from '../../EntriesList/EntriesList'
import { EntryDetailed } from '../../EntryDetailed/EntryDetailed'

import styles from "./ApiCallsView.module.sass"
import EntriesListStyles from '../../EntriesList/EntriesList.module.sass';
import { Box, Drawer, Slide } from '@mui/material';
import entryDetailsOpenAtom from '../../../recoil/entryDetailsOpen/atom';
import entryDetailsViewModeAtom from '../../../recoil/entryDetailsViewMode';

interface ApiCallsViewProps {
  scrollableRef: React.MutableRefObject<VirtualizedListHandle>;
}

interface PresentationProps {
  children: JSX.Element
  mode: 'drawer' | 'slide'
  open: boolean
  handleClose: () => void
}

const Presentation: React.FC<PresentationProps> = ({ children, mode, open, handleClose }) => {
  if (mode === 'drawer') {
    return (
      <Drawer
        anchor='bottom'
        open={open}
        onClose={handleClose}
        PaperProps={{ sx: { height: '76vh' }}}
      >
        {children}
      </Drawer>
    )
  }

  if (mode === 'slide') {
    return (
      <Slide direction={"left"} in={open} mountOnEnter unmountOnExit>
        {children}
      </Slide>
    )
  }

  return open && children
}

export const ApiCallsView: React.FC<ApiCallsViewProps> = ({ scrollableRef }) => {
  const [entryDetailsOpen, setEntryDetailsOpen] = useRecoilState(entryDetailsOpenAtom)

  const handleCloseEntryDetails = () => setEntryDetailsOpen(false)

  const trafficPageContainerRef = useRef(null)

  const viewMode = useRecoilValue(entryDetailsViewModeAtom)

  return (
    <div className={styles.TrafficPageContainer} ref={trafficPageContainerRef}>
      <div className={styles.TrafficPageListContainer}>
        <div className={EntriesListStyles.container}>
          <EntriesList scrollableRef={scrollableRef} />
        </div>
      </div>
      <Presentation mode={viewMode} open={entryDetailsOpen} handleClose={handleCloseEntryDetails}>
        <Box boxSizing='border-box' flex='0 0 50%' width={viewMode === 'slide' ? '50vw' : 'unset'} paddingRight='16px'>
          <div className={styles.EntryDetailedContainer} id='rightSideContainer'>
            <EntryDetailed />
          </div>
        </Box>
      </Presentation>
    </div>
  )
}
