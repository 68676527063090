import './App.sass';
import React from "react";
import { TrafficPage } from "./components/Pages/TrafficPage/TrafficPage";
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material';

import * as UI from "./components/UI";
import useQueryRestore from './hooks/useQueryRestore';
import useInterceptor from './hooks/useInterceptor';
import { OidcAuthProvider } from './components/UI/Auth/OidcAuth/OidcAuthProvider';
import useLicenseUpdater from './hooks/useLicenseUpdater';
import useAuthTracker from './hooks/useAuthTracker';
import { useIntercomChat } from './hooks/useIntercomChat'
import { useIntercomEvents } from './hooks/useIntercomEvents'
import useSentryTracking from './hooks/useSentryTracking'
import useElementRouter from './hooks/useElementRouter'
import { Detector } from "react-detect-offline";
import { useSetRecoilState } from 'recoil'
import onlineAtom from './recoil/online'
import { PushLicenseOnOidcAuth } from './components/healdess/PushLicenseOnOidcAuth'
import { appOidcAuthTypeEnabled } from './types/global'

const App: React.FC = () => {
  useSentryTracking()
  useAuthTracker()
  useInterceptor()
  useLicenseUpdater()
  useIntercomChat()
  useIntercomEvents()
  useElementRouter()
  useQueryRestore()

  const setOnline = useSetRecoilState(onlineAtom)

  return (
    <StyledEngineProvider injectFirst>
      <OidcAuthProvider>
        <ThemeProvider theme={createTheme(({}))}>
          <Detector render={({ online }) => {
            setOnline(online)
            return null
          }} />
          <UI.Auth>
            {appOidcAuthTypeEnabled() && <PushLicenseOnOidcAuth />}
          </UI.Auth>
          <div className="kubesharkApp">
            <TrafficPage />
          </div>
        </ThemeProvider>
      </OidcAuthProvider>
    </StyledEngineProvider>
  );
}

export default App;
