export interface TrafficEntryShownData {
  node?: boolean
  namespace?: boolean
  service?: boolean
  pod?: boolean
  containerWithPort?: boolean
  process?: boolean
  socket?: boolean
}

export type EntryPoint = 'src' | 'dst'

export const EntrySource: EntryPoint = 'src'
export const EntryDestination: EntryPoint = 'dst'
