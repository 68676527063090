import React from 'react'
import variables from '../../../../variables.module.scss'
import { CircularProgress, Fab } from '@mui/material'
import StopCircleRoundedIcon from '@mui/icons-material/StopCircleRounded'
import { authorizeAction, AUTHZ_ACTIONS } from '../../../UI/Auth/SamlAuth/Authorization'
import Tooltip from '@mui/material/Tooltip'

interface DeactivateScriptButtonProps {
  darkModeEnabled: boolean
  inProgress: boolean
  onClick: () => void
}

export const DeactivateScriptButton: React.FC<DeactivateScriptButtonProps> = ({ darkModeEnabled, inProgress, onClick }) => {
  return (
    <Tooltip
      title={
        !authorizeAction({
          [AUTHZ_ACTIONS.SCRIPTING_PERMISSIONS_KEY] : AUTHZ_ACTIONS.SCRIPTING_PERMISSIONS.CAN_ACTIVATE
        }) ?
          'You are not authorized to deactivate scripts' : null
      }
      placement='top-start'
      arrow
    >
      <Fab
        variant='extended'
        color='warning'
        onClick={onClick}
        disableRipple={
          inProgress ||
          !authorizeAction({
            [AUTHZ_ACTIONS.SCRIPTING_PERMISSIONS_KEY]: AUTHZ_ACTIONS.SCRIPTING_PERMISSIONS.CAN_ACTIVATE
          })
        }
        sx={{
          backgroundColor: () => {
            if (!authorizeAction({
              [AUTHZ_ACTIONS.SCRIPTING_PERMISSIONS_KEY]: AUTHZ_ACTIONS.SCRIPTING_PERMISSIONS.CAN_ACTIVATE
            })) {
              return variables.darkSlateColor
            }

            return darkModeEnabled
              ? variables.lightOrangeColor
              : variables.orangeColor
          },
          opacity: inProgress ||
            !authorizeAction({
              [AUTHZ_ACTIONS.SCRIPTING_PERMISSIONS_KEY]: AUTHZ_ACTIONS.SCRIPTING_PERMISSIONS.CAN_ACTIVATE
            }) ? 0.8 : 1,
          filter: `grayscale(${!authorizeAction({
            [AUTHZ_ACTIONS.SCRIPTING_PERMISSIONS_KEY]: AUTHZ_ACTIONS.SCRIPTING_PERMISSIONS.CAN_ACTIVATE
          }) ? 1 : 0})`,
          cursor: inProgress ||
            !authorizeAction({
              [AUTHZ_ACTIONS.SCRIPTING_PERMISSIONS_KEY]: AUTHZ_ACTIONS.SCRIPTING_PERMISSIONS.CAN_ACTIVATE
            }) ? 'not-allowed' : 'pointer',
        }}
      >
        {inProgress && <CircularProgress size={14} color='inherit' sx={{ mr: '10px' }} />}
        {!inProgress && <StopCircleRoundedIcon
          htmlColor={
            darkModeEnabled
              ? variables.lighterOrangeColor
              : variables.mainBackgroundColor
          }
          sx={{ mr: '10px' }}
        />}
        {inProgress ? 'Deactivating now...' : 'Deactivate'}
      </Fab>
    </Tooltip>
  )
}
