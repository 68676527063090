import React, { useCallback, useEffect, useRef, useState } from 'react'

import './ConsoleView.sass'
import styles from './ConsoleView.module.sass'
import { Box, Grow, Typography } from '@mui/material'
import { LazyLog } from 'react-lazylog'

import variables from '../../../variables.module.scss'
import { ConsoleTab } from './ConsoleTab/ConsoleTab'
import { FullscreenViewButton } from '../../UI/FullscreenView/FullscreenViewButton'
import CodeRoundedIcon from '@mui/icons-material/CodeRounded'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import scriptLogsAtom from '../../../recoil/scriptLogs'
import { ScriptLogs } from '../../../hooks/useScriptLogTracker'
import scriptsAtom from '../../../recoil/scripts'
import { getScripts } from '../ScriptingView/api/getScripts'
import { Script, ScriptMap } from '../../modals/ScriptingModal/ScriptingTypes'
import { ConsoleBanner } from './ConsoleBanner/ConsoleBanner'
import { trafficViewTabs } from '../../../recoil/trafficViewTab/atom'
import trafficViewTabAtom from '../../../recoil/trafficViewTab'
import scriptIndexOnLoadAtom from '../../../recoil/scriptIndexOnLoad'
import scriptLogIndexOnLoadAtom from '../../../recoil/scriptLogIndexOnLoad'
import { DeactivateScriptButton } from '../ScriptingView/ScriptButtons/DeactivateScriptButton'
import { postDeactivateScript } from '../ScriptingView/api/postDeactivateScript'
import { postActivateScript } from '../ScriptingView/api/postActivateScript'
import { ActivateScriptButton } from '../ScriptingView/ScriptButtons/ActivateScriptButton'
import { GoToScriptButton } from './GoToScriptButton'
import { ShowNewLogsButton } from './ShowNewLogsButton'
import scriptAllWsLogsAtom from '../../../recoil/scriptAllWsLogs'
import { authorizeAction, AUTHZ_ACTIONS } from '../../UI/Auth/SamlAuth/Authorization'

const MAX_SHOWN_TABS = 8

export const ConsoleView = () => {
  const [scripts, setScripts] = useRecoilState(scriptsAtom)
  const scriptLogs = useRecoilValue(scriptLogsAtom)
  const setScriptAllWsLogs = useSetRecoilState(scriptAllWsLogsAtom)
  const [scriptLogIndexOnLoad, setScriptLogIndexOnLoad] = useRecoilState(scriptLogIndexOnLoadAtom)

  const setTrafficViewTab = useSetRecoilState(trafficViewTabAtom)
  const setScriptIndexOnLoad = useSetRecoilState(scriptIndexOnLoadAtom)

  const [scriptsLoaded, setScriptsLoaded] = useState(false)
  const [shownScriptLogs, setShownScriptLogs] = useState<ScriptMap>({})
  const [dropdownScriptLogs, setDropdownScriptLogs] = useState<ScriptMap>({})
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const [consoleLogs, setConsoleLogs] = useState('Waiting for logs...')
  const [compactView, setCompactView] = useState(false)
  const [activationButtonClicked, setActivationButtonClicked] = useState(false)
  const [followConsole, setFollowConsole] = useState(true)
  const [newLogsAvailable, setNewLogsAvailable] = useState(false)

  const listOuterRef = useRef(null)

  const darkModeEnabled = true

  const setFirstAvailableConsoleTab = useCallback(() => {
    if (scripts && Object.keys(scripts).length > 0) {
      const firstActiveScriptVal = Object.entries(scripts).find(scriptVal => {
        const script: Script = {...scriptVal[1]}
        return script.active && !script?.deactivatedFromConsole
      })

      if (firstActiveScriptVal === undefined) {
        return
      }

      const firstActiveScriptIndex = parseInt(firstActiveScriptVal[0])
      setSelectedIndex(firstActiveScriptIndex)
    }
  }, [scripts])

  // Set initial console tab
  useEffect(() => {
    if (selectedIndex !== -1) {
      return
    }

    if (!scriptLogs || Object.keys(scriptLogs).length === 0) {
      setFirstAvailableConsoleTab()
      return
    }

    const scriptLog = Object.values(scriptLogs)[0] as ScriptLogs

    const script = scripts[scriptLog.scriptIndex]

    const isScriptInactive= Object.keys(scripts).length > 0 &&
      script && !script.active

    if (isScriptInactive) {
      setFirstAvailableConsoleTab()
      return
    }

    setSelectedIndex(scriptLog.scriptIndex)
  }, [scripts, scriptLogs])

  // Continuously set console logs, if console tab is chosen
  useEffect(() => {
    if (selectedIndex === -1 || !scriptLogs || Object.keys(scriptLogs).length === 0) {
      return
    }

    if (scripts[selectedIndex]?.deactivatedFromConsole === true) {
      return
    }

    if (followConsole) {
      const scriptLog = scriptLogs[selectedIndex] as ScriptLogs
      setConsoleLogs(scriptLog?.logs?.join('') || 'Waiting for logs...')
    }
  }, [scripts, scriptLogs, selectedIndex, followConsole])

  useEffect(() => {
    const lazyLogList = document.querySelector('.react-lazylog')
    if (lazyLogList && lazyLogList.scrollHeight > lazyLogList.clientHeight) {
      setNewLogsAvailable(true)
    }
  }, [scriptLogs])

  useEffect(() => {
    if (followConsole) {
      setNewLogsAvailable(false)
    }
  }, [followConsole])

  // Set console logs once tab changed
  useEffect(() => {
    const scriptLog: ScriptLogs = scriptLogs[selectedIndex]
    setConsoleLogs(scriptLog?.logs?.join('') || 'Waiting for logs...')

    setFollowConsole(true)
    setNewLogsAvailable(false)
  }, [selectedIndex])

  // Open a specific console tab once Console view is loaded.
  // This hook executes if user clicks "Script Logs" from Script view.
  useEffect(() => {
    if (scriptLogIndexOnLoad === null || !scriptsLoaded) {
      return
    }

    setSelectedIndex(scriptLogIndexOnLoad)

    const scriptLog: ScriptLogs = scriptLogs[scriptLogIndexOnLoad]

    const scriptLogLines = scriptLog?.logs?.join('') || 'Waiting for logs...'

    setConsoleLogs(scriptLog ? scriptLogLines : 'Waiting for logs...')
    setScriptLogIndexOnLoad(null)
  }, [scriptLogIndexOnLoad, scriptsLoaded, scriptLogs])

  // Get all scripts once Console view is loaded
  useEffect(() => {
    getScripts().then(scripts => {
      setScripts(scripts)
    })

    return () => setScriptLogIndexOnLoad(null)
  }, [])

  // Distribute scripts into main tabs & dropdown tabs
  useEffect(() => {
    if (!scripts) {
      return
    }

    const activeScripts: Script[] = []
    Object.keys(scripts).map((scriptIndex) => {
      const script = {...scripts[scriptIndex]}
      script.index = parseInt(scriptIndex)

      if (script.active) {
        activeScripts.push(script)
      }
    })

    const shownScripts = activeScripts.slice(0, MAX_SHOWN_TABS)
    const dropdownScripts = activeScripts.slice(MAX_SHOWN_TABS)

    if (activeScripts.length > MAX_SHOWN_TABS) {
      setShownScriptLogs(shownScripts)
      setDropdownScriptLogs(dropdownScripts)
    } else {
      setShownScriptLogs(shownScripts)
      setDropdownScriptLogs([])
    }

    setTimeout(() => setScriptsLoaded(true), 500)
  }, [scripts])

  const handleDeactivateScript = () => {
    if (!authorizeAction({
      [AUTHZ_ACTIONS.SCRIPTING_PERMISSIONS_KEY]: AUTHZ_ACTIONS.SCRIPTING_PERMISSIONS.CAN_ACTIVATE
    })) {
      return
    }

    setActivationButtonClicked(true)

    postDeactivateScript(selectedIndex).then(() => {
      setScripts(prevScripts => {
        const updatedScripts = {...prevScripts}

        const updatedScript = {...updatedScripts[selectedIndex]}
        updatedScript.deactivatedFromConsole = true

        updatedScripts[selectedIndex] = updatedScript

        return updatedScripts
      })

      setScriptAllWsLogs(scriptLogs => {
        const updatedScriptLogs = {...scriptLogs}

        const updatedScriptLog = {...updatedScriptLogs[selectedIndex]}
        updatedScriptLog.logs = []

        updatedScriptLogs[selectedIndex] = updatedScriptLog

        return updatedScriptLogs
      })

      setConsoleLogs('Script deactivated. Logs are cleared.')
    }).then(() => setActivationButtonClicked(false))
  }

  const handleActivateScript = () => {
    if (!authorizeAction({
      [AUTHZ_ACTIONS.SCRIPTING_PERMISSIONS_KEY]: AUTHZ_ACTIONS.SCRIPTING_PERMISSIONS.CAN_ACTIVATE
    })) {
      return
    }

    setActivationButtonClicked(true)

    postActivateScript(selectedIndex).then(() => {
      setScripts(prevScripts => {
        const updatedScripts = {...prevScripts}

        const updatedScript = {...updatedScripts[selectedIndex]}
        updatedScript.deactivatedFromConsole = false

        updatedScripts[selectedIndex] = updatedScript

        return updatedScripts
      })
    }).then(() => {
      setActivationButtonClicked(false)
      setFollowConsole(true)
      setNewLogsAvailable(false)
    })
  }

  return (
    <div className={styles.ConsoleViewContainer}>
      <Box
        className={styles.FillAllSpaceContainer}
        padding='0 16px'
        paddingTop='10px'
        position='relative'
      >
        {!scriptsLoaded && (
          <ConsoleBanner
            title='Looking for active script logs...'
            icon={
              <SearchRoundedIcon
                htmlColor={variables.slateColor}
                sx={{
                  fontSize: '96px'
                }}
              />
            }
          />
        )}
        {scriptsLoaded && Object.entries(shownScriptLogs).length === 0 && (
          <ConsoleBanner
            title='Create & activate a script to start receiving logs'
            icon={
              <CodeRoundedIcon
                htmlColor={variables.slateColor}
                sx={{
                  fontSize: '96px'
                }}
              />
            }
          />
        )}
        {scriptsLoaded && Object.entries(shownScriptLogs).length > 0 && (
          <>
            <Box
              boxSizing='border-box'
              width='100%'
              display='flex'
              alignItems='center'
              bgcolor={variables.githubEditorBackgroundColorLight}
            >
              <Box className={styles.ConsoleTabsContainer}>
                {Object.values(dropdownScriptLogs).length > 0 && (
                  <ConsoleTab
                    selected={
                      Object.values(dropdownScriptLogs).findIndex(
                        (script) => selectedIndex === script.index
                      ) !== -1
                    }
                    asDropdown
                    dropdownTabs={Object.values(dropdownScriptLogs).map(
                      (script) => {
                        return {
                          title: script.title,
                          index: script.index,
                          selected: selectedIndex === script.index,
                          selectedIndex: selectedIndex,
                          unread: scriptLogs[script.index]?.unread,
                          onSelection: (index) => {
                            setSelectedIndex(index)
                          }
                        }
                      }
                    )}
                  />
                )}
                {Object.values(shownScriptLogs).map((script) => {
                  return (
                    <ConsoleTab
                      key={script.index}
                      index={script.index}
                      selected={selectedIndex === script.index}
                      selectedIndex={selectedIndex}
                      unread={scriptLogs[script.index]?.unread}
                      onSelection={(index) => setSelectedIndex(index)}
                      title={script.title}
                    />
                  )
                })}
              </Box>
              <Box
                height='40px'
                maxHeight='40px'
                boxSizing='border-box'
                padding='4px 10px'
                display='flex'
                alignItems='center'
                gap='5px'
                borderBottom={`1px solid ${variables.slateColor}`}
              >
                <FullscreenViewButton
                  iconColor={variables.headerBackgroundColor}
                  bgColor='transparent'
                  tooltipPlacement='top-start'
                  onChange={(fullscreen) => setCompactView(fullscreen)}
                  onInit={(fullscreen) => setCompactView(fullscreen)}
                />
              </Box>
            </Box>
            <Box className={styles.FillAllSpaceContainer}>
              <Box
                ref={listOuterRef}
                className={styles.LogContainer}
                sx={{
                  height: `calc(100vh - ${compactView ? 70 : 170}px)`
                }}
                onScroll={() => setFollowConsole(false)}
                onWheel={() => setFollowConsole(false)}
              >
                {scriptsLoaded && scripts[selectedIndex]?.deactivatedFromConsole && (
                  <Box
                    boxSizing='border-box'
                    position='absolute'
                    top={0}
                    bottom={0}
                    left={0}
                    right={0}
                    width='100%'
                    height='100%'
                    padding='64px'
                    display='flex'
                    flexDirection='column'
                    alignItems='center'
                    justifyContent='flex-end'
                    zIndex={1100}
                    sx={{
                      backgroundColor: 'black',
                      background: variables.blackBottomLinearGradient
                    }}
                  >
                    <h1
                      style={{
                        margin: 0,
                        marginBottom: '20px',
                        fontFamily: 'Roboto',
                        fontSize: '28px',
                        fontWeight: 800,
                        color: variables.lighterGrayColor
                      }}
                    >
                      Script deactivated
                    </h1>
                    <Typography
                      variant='body1'
                      fontSize={16}
                      fontFamily='Roboto'
                      fontWeight={500}
                      whiteSpace='nowrap'
                      textAlign='center'
                      color={variables.lightGrayColor}
                      sx={{
                        mb: '50px'
                      }}
                    >
                      This console tab is inactive now. Logging stopped. <br/>
                      Feel free to inspect other logs or activate the script again.
                    </Typography>
                    <Box
                      boxSizing='border-box'
                      display='flex'
                      alignItems='center'
                      gap='24px'
                    >
                      <ActivateScriptButton
                        darkModeEnabled={darkModeEnabled}
                        inProgress={activationButtonClicked}
                        onClick={handleActivateScript}
                      />
                      <GoToScriptButton
                        darkModeEnabled={darkModeEnabled}
                        onClick={() => {
                          setTrafficViewTab(trafficViewTabs.SCRIPTING)
                          setScriptIndexOnLoad(selectedIndex)
                        }}
                      />
                    </Box>
                  </Box>
                )}
                <LazyLog
                  stream
                  text={consoleLogs}
                  extraLines={1}
                  enableSearch
                  follow={followConsole}
                  selectableLines
                  height='auto'
                  width='auto'
                  style={{
                    backgroundColor: variables.githubEditorBackgroundColor
                  }}
                />
              </Box>
              {!followConsole && newLogsAvailable && (
                <Grow in>
                  <Box
                    position='absolute'
                    left='calc(50% - 85px)'
                    bottom='25px'
                  >
                    <ShowNewLogsButton onClick={() => setFollowConsole(true)}/>
                  </Box>
                </Grow>
              )}
              <Box
                display='flex'
                alignItems='center'
                gap='15px'
                sx={{
                  position: 'absolute',
                  bottom: '20px',
                  right: '20px'
                }}
              >
                {[null, undefined, false].includes(
                  scripts[selectedIndex]?.deactivatedFromConsole
                ) && (
                  <DeactivateScriptButton
                    darkModeEnabled={darkModeEnabled}
                    inProgress={activationButtonClicked}
                    onClick={handleDeactivateScript}
                  />
                )}
                <GoToScriptButton
                  darkModeEnabled={darkModeEnabled}
                  onClick={() => {
                    setTrafficViewTab(trafficViewTabs.SCRIPTING)
                    setScriptIndexOnLoad(selectedIndex)
                  }}
                />
              </Box>
            </Box>
          </>
        )}
      </Box>
    </div>
  )
}
