import { HubBaseUrl } from '../../../../consts'
import { toast } from 'react-toastify'
import { authorizeAction, AUTHZ_ACTIONS } from '../../../UI/Auth/SamlAuth/Authorization'

export const postCreateScript = (title: string, code: string) => {
  if (!authorizeAction({
    [AUTHZ_ACTIONS.SCRIPTING_PERMISSIONS_KEY]: AUTHZ_ACTIONS.SCRIPTING_PERMISSIONS.CAN_SAVE
  })) {
    return
  }

  return (
    fetch(`${HubBaseUrl}/scripts`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Kubeshark-Capture': 'ignore'
      },
      body: JSON.stringify({ title, code })
    })
      .then((response) =>
        response.ok
          ? response
          : response.text().then((err) => Promise.reject(err))
      )
      .then((response) => response.json())
      .catch((err) => {
        if (err.length > 0) {
          console.error(err)
          toast.error(err.toString(), {
            theme: 'colored'
          })
        }
      })
  )
}
