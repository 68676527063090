import React from 'react'

import CodeRoundedIcon from '@mui/icons-material/CodeRounded'
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded'
import WebhookRoundedIcon from '@mui/icons-material/WebhookRounded'
import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded'
import CloudUploadRoundedIcon from '@mui/icons-material/CloudUploadRounded'
import Filter1RoundedIcon from '@mui/icons-material/Filter1Rounded'
import ConnectWithoutContactRoundedIcon from '@mui/icons-material/ConnectWithoutContactRounded';
import WaterfallChartRoundedIcon from '@mui/icons-material/WaterfallChartRounded';
import DnsRoundedIcon from '@mui/icons-material/DnsRounded';
import PublicIcon from '@mui/icons-material/Public';
import InsightsIcon from '@mui/icons-material/Insights';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import BlockIcon from '@mui/icons-material/Block';

import SCRIPT_EMPTY from '!raw-loader!./templates/empty.js'
import SCRIPT_BOOTSTRAP from '!raw-loader!./templates/bootstrap.js'
import SCRIPT_WEBHOOK from '!raw-loader!./templates/webhook.js'
import SCRIPT_SLACK from '!raw-loader!./templates/slack.js'
import SCRIPT_LOG_TOTAL_CAPTURED_PACKET_KB_PER_MIN from '!raw-loader!./templates/logTotalPacketKbPerMin.js'
import SCRIPT_MONITORING_PASS_HTTP from '!raw-loader!./templates/monitoringPassHttp.js'
import SCRIPT_PRINT_ENV from '!raw-loader!./templates/printEnv.js'
import SCRIPT_INFLUXDB from '!raw-loader!./templates/influxDb.js'
import SCRIPT_ELASTIC from '!raw-loader!./templates/elastic.js'
import SCRIPT_S3 from '!raw-loader!./templates/s3.js'
import SCRIPT_S3_SNAPSHOT from '!raw-loader!./templates/s3Snapshot.js'
import SCRIPT_ERROR_HANDLING from '!raw-loader!./templates/errorHandling.js'
import SCRIPT_CHATGPT from '!raw-loader!./templates/chatgpt.js'
import SCRIPT_CUSTOM_PROMETHEUS_METRIC from '!raw-loader!./templates/customPrometheusMetric.js'
import SCRIPT_EXTERNAL_CONNECTION from '!raw-loader!./templates/externalConnection.js'
import SCRIPT_KINESIS from '!raw-loader!./templates/kinesis.js'
import SCRIPT_KUBESHARK_LOGS from '!raw-loader!./templates/kubesharkLogs.js'
import SCRIPT_DNS_TOP5 from '!raw-loader!./templates/dnsTop5.js'
import SCRIPT_DNS_TOP5_SERVERS from '!raw-loader!./templates/dnsTop5servers.js'
import SCRIPT_API_LATENCY_ANOMALIES from '!raw-loader!./templates/apiLatencyAnomalies.js'
import SCRIPT_INACTIVE_PODS from '!raw-loader!./templates/inactivePods.js'
import SCRIPT_REDACT_DATA from '!raw-loader!./templates/redactData.js'

const EXAMPLE_SCRIPTS = [
  SCRIPT_BOOTSTRAP,
  SCRIPT_DNS_TOP5,
  SCRIPT_DNS_TOP5_SERVERS,
  SCRIPT_API_LATENCY_ANOMALIES,
  SCRIPT_EXTERNAL_CONNECTION,
  SCRIPT_INACTIVE_PODS,
  SCRIPT_REDACT_DATA,
  SCRIPT_CUSTOM_PROMETHEUS_METRIC,
  SCRIPT_PRINT_ENV,
  SCRIPT_LOG_TOTAL_CAPTURED_PACKET_KB_PER_MIN,
  SCRIPT_KINESIS,
  SCRIPT_ERROR_HANDLING,
  SCRIPT_WEBHOOK,
  SCRIPT_S3,
  SCRIPT_KUBESHARK_LOGS,
  // SCRIPT_SLACK,
  // SCRIPT_MONITORING_PASS_HTTP,
]

const EXAMPLE_SCRIPT_TITLES = [
  "Empty",
  "Top 5 Pods Consuming the Most DNS Requests",
  "Top requested DNS endpoints by DNS server",
  "Report API Latency Anomalies",
  "Report External Communication",
  "Inactive Pods (Cost Optimization)",
  "Redact Sensitive Data",
  "Export Custom Prometheus Metric",
  "Print Environment Variables",
  "Log Total Captured Packet and KB Every Minute",
  "Export HTTP payloads in HAR format to an AWS Kinesis stream",
  "Error Handling",
  "Call a Webhook",
  "Upload PCAP File (e.g. PCAP) to an AWS S3 Bucket",
  "Kubeshark Logs and Pod Events Report",
  // "Report To a Slack Channel If HTTP Status Code is 500",
  // "Monitoring: Fail HTTP Status Code is 500, Pass Anything Else",
]

const TEMPLATE_SCRIPT_TITLES = {
  "Bootstrap Script": <CodeRoundedIcon />,
  "Top 5 DNS Consumers": <DnsRoundedIcon />,
  "Top requested DNS endpoints by DNS server": <PublicIcon />,
  "Report API Latency Anomalies": <InsightsIcon />,
  "Pods and Processes With External Communication": <ConnectWithoutContactRoundedIcon />,
  "Inactive Pods (Cost Optimization)": <MoneyOffIcon />,
  "Redact Sensitive Data": <BlockIcon />,
  "Export Custom Prometheus Metric": <Filter1RoundedIcon />,
  "Print Environment Variables": <CodeRoundedIcon />,
  "Log Total Captured Packet and KB Every Minute": <ArticleRoundedIcon />,
  "Export HTTP payloads in HAR format to an AWS Kinesis stream": <WaterfallChartRoundedIcon />,
  "Error Handling": <ErrorRoundedIcon />,
  "Call a Webhook": <WebhookRoundedIcon />,
  "Upload PCAP File (e.g. PCAP) to an AWS S3 Bucket": <CloudUploadRoundedIcon />,
//   "Kubeshark Logs and Pod Events Report": <ArticleRoundedIcon />,
  // "Report To a Slack Channel If HTTP Status Code is 500": <HttpRoundedIcon />,
  // "Monitoring: Fail HTTP Status Code is 500, Pass Anything Else": <HttpRoundedIcon />,
  // "Aggregate the HTTP Status Codes and Push Them to InfluxDB Every Minute": <HttpRoundedIcon />,
  // "Aggregate the HTTP Status Codes and Push Them to Elastic Cloud Every Minute": <HttpRoundedIcon />,
  // "Upload a PCAP Snapshot to an AWS S3 Bucket If HTTP Status Code is 500": <CloudUploadRoundedIcon />,
  // "Use ChatGPT to Detect Unprocessable HTTP Requests": <ChatRoundedIcon />,
}


const DEFAULT_TITLE = "New Script"
const DEFAULT_SCRIPT = SCRIPT_EMPTY

export {
  SCRIPT_BOOTSTRAP,
  SCRIPT_PRINT_ENV,
  SCRIPT_ERROR_HANDLING,
  SCRIPT_CUSTOM_PROMETHEUS_METRIC,
  SCRIPT_WEBHOOK,
  SCRIPT_SLACK,
  SCRIPT_LOG_TOTAL_CAPTURED_PACKET_KB_PER_MIN,
  SCRIPT_MONITORING_PASS_HTTP,
  SCRIPT_INFLUXDB,
  SCRIPT_ELASTIC,
  SCRIPT_S3,
  SCRIPT_S3_SNAPSHOT,
  SCRIPT_CHATGPT,
  EXAMPLE_SCRIPTS,
  EXAMPLE_SCRIPT_TITLES,
  TEMPLATE_SCRIPT_TITLES,
  DEFAULT_TITLE,
  DEFAULT_SCRIPT,
}
