import React from 'react'

import styles from './NavDropdown.module.sass'
import variables from '../../../variables.module.scss'

import { Menu, MenuItem, Dropdown } from '@mui/base'
import { ClickAwayListener, IconButton, Popper, Grow } from '@mui/material'
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded'

import {
  PendingActions as PendingActionsIcon
} from '@mui/icons-material'

import MoreIcon from './MoreIcon'

import { useSetRecoilState } from 'recoil'
import jobsModalOpenAtom from "../../../recoil/jobsModalOpen";

import { LinkSupport } from '../../../consts'
import { checkScriptingDisabled } from '../../../types/global'

export const NavDropdown: React.FC = () => {
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)

  const handleClick = () => {
    setOpen(!open)
  }

  const setJobsModalOpen = useSetRecoilState(jobsModalOpenAtom);

  const handleOpenJobsModal = () => {
    setOpen(false)
    setJobsModalOpen(true);
  }

  const handleOpenSupport = () => {
    window.open(LinkSupport, '_blank')
  }

  const popperModifiers = [
    {
      name: 'offset',
      options: {
        offset: [0, 10]
      }
    }
  ]

  return (
    <ClickAwayListener onClickAway={() => open && setOpen(false)}>
      <div style={{ position: 'relative' }}>
        <Dropdown open={open}>
          <IconButton
            className={styles.DropdownButton}
            ref={anchorRef}
            onClick={handleClick}
          >
            <MoreIcon />
          </IconButton>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            placement='bottom-end'
            transition
            disablePortal
            modifiers={popperModifiers}
            sx={{ zIndex: 1300 }}
          >
            {({ TransitionProps }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: 'right top'
                }}
              >
                <div>
                  <Menu slotProps={{ listbox: { className: styles.Dropdown } }}>
                    <MenuItem className={`${styles.DropdownItem} ${checkScriptingDisabled() ? styles.DropdownItemDisabled : ''}`}
                      onClick={handleOpenJobsModal}
                      disabled={checkScriptingDisabled()}>
                      <PendingActionsIcon htmlColor={checkScriptingDisabled() ? variables.lightGrayColor : variables.blueColor} />
                      <span className={styles.DropdownItemText}>Jobs</span>
                    </MenuItem>
                    <MenuItem className={styles.DropdownItem}
                      onClick={handleOpenSupport}>
                      <HelpOutlineRoundedIcon htmlColor={variables.blueColor} />
                      <span className={styles.DropdownItemText}>Support</span>
                    </MenuItem>
                  </Menu>
                </div>
              </Grow>
            )}
          </Popper>
        </Dropdown>
      </div>
    </ClickAwayListener>
  )
}
