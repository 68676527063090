import { appAuthEnabled } from '../../../../types/global'
import { checkSamlEnabled, extractJwtPayload } from './SamlAuth'
import { Utils } from '../../../../helpers/Utils'

interface AuthzActionsPayload {
  canDownloadPCAP?: boolean
  canUseScripting?: boolean
  scriptingPermissions?: {
    canSave?: boolean
    canActivate?: boolean
    canDelete?: boolean
  }
  canUpdateTargetedPods?: boolean
  canStopTrafficCapturing?: boolean
  showAdminConsoleLink?: boolean
}

export const AUTHZ_ACTIONS = {
  CAN_DOWNLOAD_PCAP: 'canDownloadPCAP',
  CAN_USE_SCRIPTING: 'canUseScripting',
  SCRIPTING_PERMISSIONS_KEY: 'scriptingPermissions',
  SCRIPTING_PERMISSIONS: {
    CAN_SAVE: 'canSave',
    CAN_ACTIVATE: 'canActivate',
    CAN_DELETE: 'canDelete',
  },
  CAN_UPDATE_TARGETED_PODS: 'canUpdateTargetedPods',
  CAN_STOP_TRAFFIC_CAPTURING: 'canStopTrafficCapturing',
  SHOW_ADMIN_CONSOLE_LINK: 'showAdminConsoleLink',
}

const getSamlAuthzActionsPayload = (): Array<AuthzActionsPayload> => {
  const jwtPayload = extractJwtPayload() ?? []
  return jwtPayload['authorizedActions'] as Array<AuthzActionsPayload>
}

export const authorizeAction = (actionSelector: string | unknown): boolean => {
  if (appAuthEnabled() && checkSamlEnabled()) {
    const authzActions = getSamlAuthzActionsPayload()
    if (authzActions === undefined) {
      return false
    }

    const authzAction = Utils.extractPropBySelector(authzActions, actionSelector);
    if (authzAction === undefined) {
      return false
    }

    return authzAction
  }

  return true
}
