import React, { useCallback } from 'react'
import { Descope, useDescope } from '@descope/react-sdk'
import { toast } from 'react-toastify'
import { setAuthSessionDetails } from '../AuthSessionDetails'
import trafficPlaybackAtom, { TRAFFIC_PLAYBACK_OK } from '../../../../recoil/trafficPlayback/atom'
import { useSetRecoilState } from 'recoil'
import resetLicenseTimersSignalAtom from '../../../../recoil/resetLicenseTimersSignal'

export const DescopeSignInForm: React.FC = () => {
  const setTrafficPlayback = useSetRecoilState(trafficPlaybackAtom);
  const setResetLicenseTimersSignal = useSetRecoilState(resetLicenseTimersSignalAtom)

  const descopeSdk = useDescope()

  const completePostAuthActions = useCallback((userId: string, name: string, email: string) => {
    setAuthSessionDetails({ userId, name, email })
    setTimeout(() => setTrafficPlayback(TRAFFIC_PLAYBACK_OK), 500)
    setResetLicenseTimersSignal(Date.now())
  }, [])

  return (
    <Descope
      flowId='sign-up-or-in-front'
      theme='light'
      onError={(err: unknown) => {
        toast.error(err.toString())
        console.error(err)
      }}
      onSuccess={() => {
        descopeSdk.me().then((sdkResponse) => {
          const { userId, name, email } = sdkResponse.data
          completePostAuthActions(userId, name, email)
        })
      }}
    />
  )
}
