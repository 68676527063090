const cacheableEntryFields = [
  'capture',
  'proto',
  'worker',
  'summary',
  'summaryQuery',
  'statusQuery',
  'methodQuery',
  'src.name',
  'src.namespace',
  'src.namespaceColorSet.color',
  'src.endpointSlice',
  'src.service',
  'src.pod',
  'src.resolutionMechanism',
  'src.cgroupId',
  'src.containerId',
  'src.hostParentProcessId',
  'dst.name',
  'dst.namespace',
  'dst.namespaceColorSet.color',
  'dst.endpointSlice',
  'dst.service',
  'dst.pod',
  'dst.resolutionMechanism',
  'dst.cgroupId',
  'dst.containerId',
  'dst.hostParentProcessId',
]

export default cacheableEntryFields
