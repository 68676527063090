import { useEffect, useRef, useState } from 'react'

import { Utils } from '../helpers/Utils'

import { nodeBackgrounds } from '../components/modals/ServiceMapModal/NodeBackgrounds'
import variables from '../variables.module.scss'

export interface NamespaceColorSet {
  color: string
}

export const useNamespaceColors = () => {
  const [usedColors, setUsedColors] = useState<Record<string, Set<number>>>({})
  const keyToColorMap = useRef<Record<string, NamespaceColorSet>>({})
  const remainingMiddleHexes = useRef<string[]>([])
  const chosenLuminances = useRef<number[]>([])

  useEffect(() => {
    const middleHexes = nodeBackgrounds.map((cluster) => {
      const middleIndex = Math.floor(cluster.colors.length / 2)
      return cluster.colors[middleIndex]
    })

    remainingMiddleHexes.current = Utils.shuffleArray(middleHexes)
  }, [])

  const assignEntryNamespaceColors = (entry) => {
    const [isSrcNsString, isDstNsString] = [
      typeof entry?.src?.namespace === 'string' && entry?.src?.namespace.length > 0,
      typeof entry?.dst?.namespace === 'string' && entry?.dst?.namespace.length > 0
    ]

    const entrySrc = {...entry.src}
    const entryDst = {...entry.dst}

    if (isSrcNsString) {
      entrySrc.namespaceColorSet = assignNamespaceColor(entrySrc.namespace)
    } else {
      entrySrc.namespaceColorSet = { color: variables.lightGrayColor }
    }

    if (isDstNsString) {
      entryDst.namespaceColorSet = assignNamespaceColor(entryDst.namespace)
    } else {
      entryDst.namespaceColorSet = { color: variables.lightGrayColor }
    }

    entry.src = entrySrc
    entry.dst = entryDst

    return entry
  }

  const assignNamespaceColor = (key: string): NamespaceColorSet => {
    if (keyToColorMap.current[key]) {
      return keyToColorMap.current[key]
    }

    const nextHex = chooseNextHexByContrast(
      remainingMiddleHexes.current,
      chosenLuminances.current
    )

    if (nextHex) {
      remainingMiddleHexes.current = remainingMiddleHexes.current.filter(
        (hex) => hex !== nextHex
      )

      chosenLuminances.current.push(Utils.calculateHexLuminance(nextHex))

      const colorSet: NamespaceColorSet = {
        color: nextHex,
      }

      keyToColorMap.current[key] = colorSet
      return colorSet
    }

    const cluster = nodeBackgrounds[
      Math.floor(Math.random() * nodeBackgrounds.length)
    ]

    const clusterName = cluster.name
    const colors = cluster.colors

    const clusterUsed = usedColors[clusterName] || new Set<number>()
    const middleIndex = Math.floor(colors.length / 2)
    let pickedIndex: number | null = null

    if (!clusterUsed.has(middleIndex)) {
      pickedIndex = middleIndex
    } else {
      for (let offset = 1; offset <= middleIndex; offset++) {
        if (!clusterUsed.has(middleIndex - offset)) {
          pickedIndex = middleIndex - offset
          break
        }
        if (!clusterUsed.has(middleIndex + offset)) {
          pickedIndex = middleIndex + offset
          break
        }
      }
    }

    if (pickedIndex === null) {
      const nonCenterIndices = colors.map((_, idx) => idx)
        .filter((idx) => idx !== middleIndex && !clusterUsed.has(idx))

      pickedIndex = nonCenterIndices.length > 0 ? nonCenterIndices[
        Math.floor(Math.random() * nonCenterIndices.length)
      ] : middleIndex
    }

    const newUsedColors = { ...usedColors }
    if (!newUsedColors[clusterName]) {
      newUsedColors[clusterName] = new Set()
    }

    newUsedColors[clusterName].add(pickedIndex)
    setUsedColors(newUsedColors)

    const colorSet: NamespaceColorSet = {
      color: colors[pickedIndex],
    }

    keyToColorMap.current[key] = colorSet
    return colorSet
  }

  const chooseNextHexByContrast = (
    remainingHexes: string[],
    chosenLuminances: number[]
  ): string | null => {
    if (remainingHexes.length === 0) return null
    if (chosenLuminances.length === 0) return remainingHexes[0]

    const lastLuminance = chosenLuminances[chosenLuminances.length - 1]
    const contrastScores = remainingHexes.map((hex) => {
      const luminance = Utils.calculateHexLuminance(hex)

      // Higher is better
      const contrastRatio = Math.abs(lastLuminance - luminance)

      return { hex, contrastRatio }
    })

    contrastScores.sort((a, b) => b.contrastRatio - a.contrastRatio)

    return contrastScores[0].hex
  }

  return { assignNamespaceColor, assignEntryNamespaceColors }
}
